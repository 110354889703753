import React from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 296px);
  grid-row-gap: 64px;
  grid-column-gap: 56px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 296px);
  }

  @media (max-width: 768px) {
    grid-template-columns: 280px;
    grid-row-gap: 48px;
  }
`

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 296px;
    height: 224px;
    margin: 0;
    margin-bottom: 16px;

    ${props =>
      props.border &&
      css`
        border: 1px solid #e5e7ec;
        border-radius: 5px;
      `}

    img {
      max-width: 100%;
    }

    @media (max-width: 544px) {
      width: 280px;
      height: 212px;
    }
  }

  .title {
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.unit.getRem(16)};
    font-weight: 500;
    margin: 0;
    margin-bottom: 8px;
  }

  .description {
    color: ${props => props.theme.colors.gray};
    font-size: ${props => props.theme.unit.getRem(16)};
    font-weight: 400;
    line-height: 1.5;
  }
`

const features = [
  {
    key: 'four_channel_automated_follow_up',
    title: '4 channel automated follow up',
    description:
      'Automatically message leads in multiple channels including SMS, Email, Voicemail and Calls to increase response rates to up to 80%.',
    border: false,
  },
  {
    key: 'on_the_go_closing',
    title: 'On the go closing',
    description:
      'Close prospects on-the-go with our mobile app, respond to leads, track statuses, complete reminders and more.',
    border: false,
  },
  {
    key: 'forced_calls',
    title: 'Forced calls',
    description:
      'Automatically get a call every time a new prospect comes in so that you can immediately call them.',
    border: false,
  },
  {
    key: 'appointment_booking',
    title: 'Appointment booking',
    description:
      'Appointment booking feature with insane follow up capabilities in SMS, Email and Voicemail.',
    border: false,
  },
  {
    key: 'autodialer',
    title: 'Autodialer',
    description: 'Dial 3 times faster with our automated prospects dialer.',
    border: true,
  },
  {
    key: 'survey_funnels',
    image: require('../images/features/group-5.svg'),
    title: 'Survey funnels',
    description:
      'Build lead generation survey funnels with call triggers, appointment bookings, deeply integrated with ContactSmarter optimized to convert insanely high.',
    border: false,
  },
  {
    key: 'lead_round_robin',
    title: 'Lead Round Robin',
    description:
      'Automatically distribute leads between team members and track the progress on each lead.',
    border: true,
  },
  {
    key: 'easy_client_onboarding',
    image: require('../images/features/group-4.svg'),
    title: 'Easy client onboarding',
    description:
      'With a single click, copy the entire follow up sequence, funnel, appointment preferences to a new client and get up and running in no time.',
    border: true,
  },
  {
    key: 'reminders',
    title: 'Reminders',
    description:
      'Remind yourself to call leads, send documents or any other sales related tasks in your leads lifecycle.',
    border: true,
  },
  {
    key: 'pipeline',
    title: 'Pipeline',
    description:
      'Build your own lead pipeline that matches your sales flow and represents how leads flow from one place to the other.',
    border: false,
  },
  {
    key: 'broadcasts',
    title: 'Broadcasts',
    description:
      'Send a mass message to segmented lists of Email or Text messages.',
    border: false,
  },
  {
    key: 'advanced_reporting',
    title: 'Advanced reporting',
    description:
      'Track metrics like funnel conversion rates, campaign messages performance and team members sales performance so that you can measure and optimize your workflow.',
    border: true,
    style: { width: '234px', height: '195px' },
  },
  {
    key: 'white_label',
    image: require('../images/features/group-16.svg'),
    title: 'White Label',
    description:
      'Are you a big agency or a course owner? white labelling allows putting your own brand on the system.',
    border: true,
    style: { marginRight: 24, marginTop: 16 },
  },
  {
    key: 'team_management',
    title: 'Team management',
    description:
      'Work as team members to see the system how they see it, track progress, set up campaigns and more.',
    border: true,
    style: { width: '289px', height: '214px' },
  },
]

const Features = () => {
  const images = useStaticQuery(graphql`
    query {
      four_channel_automated_follow_up: file(
        relativePath: { eq: "features/group-15.png" }
      ) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lead_round_robin: file(
        relativePath: { eq: "features/lead-round-robin.png" }
      ) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reminders: file(relativePath: { eq: "features/reminders.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pipeline: file(relativePath: { eq: "features/pipelines.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      autodialer: file(relativePath: { eq: "features/autodialer.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      broadcasts: file(relativePath: { eq: "features/broadcasts.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      forced_calls: file(relativePath: { eq: "features/forced-calls.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      advanced_reporting: file(
        relativePath: { eq: "features/advanced-reporting.png" }
      ) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      team_management: file(
        relativePath: { eq: "features/team-management.png" }
      ) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      on_the_go_closing: file(relativePath: { eq: "features/iphone.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appointment_booking: file(relativePath: { eq: "features/booking.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Grid>
      {features.map((feature, i) => (
        <Feature border={feature.border} key={i}>
          <div className="container">
            {feature.image ? (
              <img alt="features" style={feature.style} src={feature.image} />
            ) : (
              <Img
                alt="features"
                style={feature.style ? feature.style : { minWidth: 296 }}
                fluid={images[feature.key].sharp.fluid}
              />
            )}
          </div>
          <h3 className="title">{feature.title}</h3>
          <div className="description">{feature.description}</div>
        </Feature>
      ))}
    </Grid>
  )
}

export default Features
