import React, { useEffect } from 'react'
import styled from 'styled-components'

import Navbar from '../components/Navbar'
import Container from '../components/Container'
import Footer from '../components/Footer'

import FeaturesGrid from '../components/Features'
import GetStartedBanner from '../components/GetStartedBanner'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px 0;

  @media (max-width: 544px) {
    padding: 40px 0;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.unit.getRem(45)};
  text-align: center;
  margin: 0;
  padding: 0;

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(26)};
  }
`

const GetStartedButton = styled.a`
  background-color: ${props => props.theme.colors.green};
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.unit.getRem(18)};
  font-weight: 600;
  text-transform: uppercase;
  margin: 40px 0 80px 0;
  width: 256px;
  padding: 16px 0;
  transition: background-color 250ms ease-in;
  text-transform: uppercase;

  :hover {
    background-color: ${props => props.theme.colors.hover.green};
  }

  :focus {
    background-color: ${props => props.theme.colors.focus.green};
  }

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(12)};
    width: 184px;
    padding: 8px 0;
  }
`

const Features = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // Segment tracking page
      window.analytics.track('FeaturesPage Loaded')
      // Facebook pixel tracking page
      window.fbq('track', 'FeaturesPage Loaded')
    }
  }, [])

  return (
    <React.Fragment>
      <Navbar black />

      <Container>
        <Wrapper>
          <Title>Industry leading features</Title>
          <GetStartedButton
            href="https://app.contactsmarter.com/signup"
            style={{ textDecoration: 'none' }}
          >
            Get started for free
          </GetStartedButton>
          <FeaturesGrid />
        </Wrapper>
      </Container>

      <Container>
        <GetStartedBanner />
      </Container>

      <Footer />
    </React.Fragment>
  )
}

export default Features
